import { Controller } from "stimulus"
import Rails from 'rails-ujs';

export default class extends Controller {
  static targets = [ "form", "dropdown" ]

  save() {
    let elements = this.formTarget.elements
    for (let i = 0, len = elements.length; i < len; ++i) {
      elements[i].readOnly = true;
    }
    Rails.fire(this.formTarget, 'submit');
  }
}
