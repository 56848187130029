import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]

  add_association(event) {
    event.preventDefault()
    let content = this.templateTarget.innerHTML.replace(/-1986/g, new Date().getTime())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()

    let item = event.target.closest(".nested-fields")

    if (item.dataset.templateRecord == "true") {
      wrapper.parentNode.removeChild(child);
    } else {
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    }
  }
}
