import { Controller } from "stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = [ "mainfolders", "subfolders" ]

  getSubFolder() {
    let mainFolder = this.mainfoldersTarget.value
    let dropdown = this.subfoldersTarget
    dropdown.length = 0
    //console.log(mainFolder).get(`/folders/${location.mainFolder}/get_sub_folders` + '.json')
    axios.get(`/folders/${mainFolder}/get_sub_folders.json`)
      .then(function (response) {
        // handle success
        for (var i = 0; i < response.data.length; i++) {
          let opt = document.createElement('option');
          opt.value = response.data[i].id;
          opt.innerHTML = response.data[i].name;
          dropdown.appendChild(opt);
        }
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
      })
  }
}
