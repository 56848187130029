import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['option', 'score', 'comment', 'response', 'selectedOption', 'tab']

  initialize() {
    this.setActive()
  }

  setActive() {
    for (let i = 0; i < this.tabTargets.length; i++) {
      if (this.tabTargets[i].dataset.score == this.scoreTarget.value) {
        this.tabTargets[i].classList.add(this.tabTargets[i].dataset.chosen, 'font-bold')
      }
    }
  }

  select(event) {
    event.preventDefault()
    // Check if response box is empty, otherwise double check user wants to overwrite.
    if (!this.responseTarget.value) {
      this.setResponse(event)
    } else {
      if( confirm("Warning! This will overwrite your current response. Do you wish to continue?") == true ) {
        this.setResponse(event)
        return true
      } else {
        return false
      }
    }
  }

  setResponse(event) {
    const autoSave = this.application.getControllerForElementAndIdentifier(document.querySelector('#save-record'), "auto-save");
    this.scoreTarget.value = event.target.dataset.score
    this.commentTarget.value = (event.target.dataset.comment) ? event.target.dataset.comment : null
    this.responseTarget.value = event.target.dataset.response
    this.selectedOptionTarget.value = event.target.dataset.id
    autoSave.save()
  }
}
