import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  autoExpand() {
    let contentArea;
    if (document.documentElement.clientWidth >= 1200) {
      contentArea = document.getElementById('content-wrapper');
    } else {
      contentArea = document.documentElement;
    }

    let currentPosition = contentArea.scrollTop;
    this.inputTarget.style.height = 'inherit';
    this.inputTarget.style.height = (this.inputTarget.scrollHeight + 5) + 'px';

    contentArea.scrollTop = currentPosition;
  }

  connect() {
    setTimeout(() => {
      this.autoExpand();
    }, "1000")
  }
}
