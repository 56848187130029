import Turbolinks from "turbolinks";
import Rails from 'rails-ujs';
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

require("flatpickr/dist/themes/airbnb.css");
Rails.start();
Turbolinks.start();

import "../css/application.css";
import '../images';

// Stimulus
const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))
