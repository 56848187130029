import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field", "buttonWrapper", "input" ]

  showField() {
    this.fieldTarget.classList.remove('hidden')
    this.fieldTarget.classList.add('block')
    this.buttonWrapperTarget.classList.remove('flex')
    this.buttonWrapperTarget.classList.add('hidden')
  }

  connect() {
    if (this.inputTarget.value.length > 0) {
      this.showField()
    } 
  }
}
