import flatpickr from 'flatpickr'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dateField']

  initialize() {
    flatpickr(this.dateFieldTarget, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d"
    })
  }
}
