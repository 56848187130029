import { Controller } from "stimulus"

export default class extends Controller {
  open(event) {
    event.preventDefault()
    let modalId = event.target.dataset.value
    let modalElement = document.getElementById(modalId)
    modalElement.classList.remove('hidden')
    modalElement.classList.add('fixed')
    modalElement.classList.add('sm:flex')
  }

  close() {
    let modalId = event.target.dataset.value
    let modalElement = document.getElementById(modalId)
    modalElement.classList.add('hidden')
    modalElement.classList.remove('fixed')
    modalElement.classList.remove('sm:flex')
  }
}
