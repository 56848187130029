import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "option", "select", "all", "options" ]

  showAll() {
    this.removeFill()
    this.addFill()
    this.buildOptions(JSON.parse(this.optionsTarget.dataset.options))
  }

  setDropdown(event) {
    let options = JSON.parse(this.optionsTarget.dataset.options)
    this.removeFill()
    this.addFill()
    this.buildOptions(options.filter(el => el.score == event.currentTarget.dataset.score))
  }

  buildOptions(options) {
    this.removeOptions()
    for (let i = 0; i < options.length; i++) {
      if(options[i].id != this.selectTarget.dataset.current) {
        let option = document.createElement("option");
        option.value = options[i].id
        if(options[i].comment) {
          option.dataset.comment = options[i].comment
        }
        option.dataset.type = options[i].score
        option.text = options[i].name
        this.selectTarget.add(option)
      }
    }
  }

  removeOptions() {
    let current = this.selectTarget.dataset.current
    console.log(current)
    for(let i = 0; i < this.selectTarget.options.length; i++) {
      if(current != this.selectTarget.options[i].value && this.selectTarget.options[i].text != "Select option") {
        this.selectTarget.remove(i)
        i--
      }
    }
  }

  addFill(){
    event.currentTarget.classList.add('border-black')
    event.currentTarget.classList.add('border-4')
  }

  removeFill() {
    this.allTarget.classList.remove('border-4')
    this.allTarget.classList.remove('border-black')
    for (let i = 0; i < this.optionTargets.length; i++) {
      this.optionTargets[i].classList.remove('border-black')
      this.optionTargets[i].classList.remove('border-4')
    }
  }

  initialize() {
  }
}
